@use 'global' as *;
// ---------------------------------------------------------

.button,
.linktag {
  display: inline-block;

  @include hover {
    text-decoration: none;
  }

  &:disabled {
    border: none;
    cursor: not-allowed;

    @include hover {
      cursor: not-allowed;
    }
  }
}

// --------------------------------------------------------- / themes

.theme {
  &_default {
    @include pill-button;

    background-color: var(--color-amp-yellow);
    border: 1.5px solid var(--color-amp-yellow);
    color: var(--color-off-black);

    @include hover {
      background-color: var(--color-limoncello);
      box-shadow: 0 0 4px 0 rgb(234 255 95 / 75%);
    }
  }

  // ------------------------------------------------------

  &_dark {
    @include pill-button;

    background-color: var(--color-off-black);
    border: 1.5px solid var(--color-off-black);
    color: var(--color-white);

    @include hover {
      background-color: var(--color-midnight);
      border: 1.5px solid var(--color-midnight);
    }
  }

  // ------------------------------------------------------

  &_arrow_dark {
    @include arrow;

    @include hover {
      color: var(--color-dusk);

      &::before {
        background: var(--color-dusk);
      }

      &::after {
        border-right: 1.5px solid var(--color-dusk);
        border-top: 1.5px solid var(--color-dusk);
      }
    }

    @include media($small-highest, down) {
      margin-bottom: 20px;
    }
  }

  &_arrow_light {
    @include arrow;

    color: var(--color-ice);

    &::before {
      background: var(--color-ice);
    }

    &::after {
      border-right: 1.5px solid var(--color-ice);
      border-top: 1.5px solid var(--color-ice);
    }

    &:hover {
      color: var(--color-prime-teal);

      &::before {
        background: var(--color-prime-teal);
      }

      &::after {
        border-right: 1.5px solid var(--color-prime-teal) !important;
        border-top: 1.5px solid var(--color-prime-teal) !important;
      }
    }

    &:not(:disabled) {
      @include hover {
        color: var(--color-prime-teal);
      }
    }

    @include media($small-highest, down) {
      margin-bottom: 20px;
    }
  }

  // ------------------------------------------------------

  &_arrow_horizontal_light {
    @include default-button;

    background-color: transparent;
    border: none;
    color: var(--color-ice);

    svg {
      color: var(--color-ice);
      transition: transform 250ms ease;
    }

    @include hover {
      background-color: transparent;
      border: none;
      color: var(--color-prime-teal);

      svg {
        color: var(--color-prime-teal);
        transform: translateX(5px);
      }
    }
  }

  &_arrow_horizontal_dark {
    @include default-button;

    background-color: transparent;
    border: none;
    color: var(--color-dusk);

    svg {
      color: var(--color-dusk);
      transition: transform 250ms ease;
    }

    @include hover {
      background-color: transparent;
      border: none;
      color: var(--color-midnight);

      svg {
        color: var(--color-midnight);
        transform: translateX(5px);
      }
    }
  }

  // ------------------------------------------------------

  &_outline_teal_transparent {
    @include pill-button;

    background: transparent;
    border: 1.5px solid var(--color-prime-teal);
    color: var(--color-prime-teal);

    @include hover {
      filter: brightness(85%);
    }
  }

  &_outline_yellow_transparent {
    @include pill-button;

    background: transparent;
    border: 1.5px solid var(--color-limoncello);
    color: var(--color-limoncello);
    transition: background-position 200ms ease-in-out;

    @include hover {
      box-shadow: 0 0 4px 0 rgb(234 255 95 / 75%);
      color: var(--color-amp-yellow);
    }
  }

  &_prime_teal {
    @include pill-button;

    background: var(--color-prime-teal);
    border: 1.5px solid var(--color-prime-teal);
    color: var(--color-off-black);

    @include hover {
      background: var(--color-prime-teal-light);
      border: 1.5px solid var(--color-prime-teal-light);
      box-shadow: 0 0 4px 0 rgb(137 234 240 / 75%);
    }
  }

  &_outline_dark {
    @include pill-button;

    background: var(--color-white);
    border: 1.5px solid var(--color-off-black);
    color: var(--color-off-black);

    @include hover {
      background: var(--color-moss);
      border: 1.5px solid var(--color-off-black);
    }
  }

  &_gradient_blue_green {
    @include pill-button;

    background: linear-gradient(90deg, #54d3de, #edff95 51%, #54d3de) 0 / 200%;
    transition: all 250ms ease-in;

    @include hover {
      background-position: 100%;
      box-shadow: 0 0 4px 0 rgb(234 255 95 / 75%);
    }
  }
}

.icon_new_tab {
  display: none;

  @include media($full-navigation) {
    bottom: 1.5px;
    display: inline-block;
    height: 24px;
    margin-left: 0.6rem;
    position: relative;
  }
}

.link_new_tab {
  @include media($full-navigation) {
    width: fit-content;
  }
}
